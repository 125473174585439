import store from "@/state/store";
import orderRoutes from "./order-routes";
import productRoutes from "./product-routes";
import courseRoutes from "./course-routes";
import immigrationRequestRoutes from "./immigration-request-routes";
import buyRequestRoutes from "./buy-request-routes";
import requestTrackingRoutes from "./request-tracking-routes";

export default [
  ...productRoutes,
  ...orderRoutes,
  ...courseRoutes,
  ...immigrationRequestRoutes,
  ...buyRequestRoutes,
  ...requestTrackingRoutes,
  {
    path: "/campaign/sms",
    name: "campagnes-sms",
    meta: { title: "Campagnes SMS", authRequired: true },
    component: () => import("../views/sms/CampaignSMSDashboardView.vue"),
    beforeEnter: () => {
      store.dispatch("campaigns/findAll");
    },
  },
  {
    path: "/campaign/sms/create",
    name: "campagnes-sms-create",
    meta: { title: "Nouvelle campagnes SMS", authRequired: true },
    component: () => import("../views/sms/CampaignSMSCreateView.vue"),
  },
  {
    path: "/campaign/sms/:campaignId/details",
    name: "campagnes-sms-details",
    meta: { title: "Détails campagnes SMS", authRequired: true },
    component: () => import("../views/sms/CampaignSMSDetailsView.vue"),
  },
  {
    path: "/profile-settings",
    name: "profile-settings",
    component: () => import("../views/apps/ecommerce/profile-settings.vue"),
    meta: {
      title: "Mon Profil",
    },
  },
  {
    path: "/my-dates-settings",
    name: "my-dates-settings",
    component: () => import("../views/apps/ecommerce/my-dates-settings.vue"),
    meta: {
      title: "Dates de départ",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/apps/ecommerce/products.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        next();
      },
    },
    component: () => import("../views/auth/logout/basic"),
  },
  {
    path: "/chat",
    name: "chat",
    meta: { title: "Chat", authRequired: true },
    component: () => import("../views/apps/chat"),
  },
  {
    path: "/apps-file-manager",
    name: "file manager",
    meta: { title: "File Manager", authRequired: true },
    component: () => import("../views/apps/filemanager"),
  },
  {
    path: "/apps-todo",
    name: "To Do List",
    meta: { title: "To Do List", authRequired: true },
    component: () => import("../views/apps/todo"),
  },
  {
    path: "/apps/projects-list",
    name: "projects-list",
    meta: { title: "Projects List", authRequired: true },
    component: () => import("../views/apps/projects/list"),
  },
  {
    path: "/apps/tasks-list-view",
    name: "tasks-list-view",
    meta: { title: "Taks List", authRequired: true },
    component: () => import("../views/apps/tasks/list-view"),
  },
  {
    path: "/customers",
    name: "customers",
    meta: { title: "Customers", authRequired: true },
    component: () => import("../views/apps/ecommerce/customers"),
  },

  {
    path: "/tracking-customers",
    name: "tracking-customers",
    meta: { title: "Customers", authRequired: true },
    component: () => import("../views/apps/ecommerce/tracking-customers"),
  },
  {
    path: "/products",
    name: "products",
    meta: { title: "Products", authRequired: true },
    component: () => import("../views/apps/ecommerce/products"),
  },
  {
    path: "/comments-on-product",
    name: "comments-on-product",
    meta: {
      title: "Liste des commentaires sur un produit",
      authRequired: true,
    },
    component: () => import("../views/apps/ecommerce/comment-on-product"),
  },
  {
    path: "/faq-questions",
    name: "faq-questions",
    meta: { title: "Liste des messages recus", authRequired: true },
    component: () => import("../views/apps/ecommerce/faq-questions"),
  },
  {
    path: "/pickup-dates",
    name: "pickup-dates",
    meta: { title: "Dates de ramassage", authRequired: true },
    component: () => import("../views/pickup-dates"),
  },
  {
    path: "/pickup-plane-fees",
    name: "pickup-plane-fees",
    meta: { title: "plane-fees de ramassage", authRequired: true },
    component: () => import("../views/apps/ecommerce/pickup-plane-fees"),
  },
  {
    path: "/pickup-sea-fees",
    name: "pickup-sea-fees",
    meta: { title: "sea-fees de ramassage", authRequired: true },
    component: () => import("../views/apps/ecommerce/pickup-sea-fees"),
  },
  {
    path: "/contacts-messages",
    name: "contacts-messages",
    meta: { title: "Liste des messages recus", authRequired: true },
    component: () => import("../views/apps/ecommerce/contacts-messages"),
  },
  {
    path: "/general-setting",
    name: "general-setting",
    meta: { title: "Gestion generale du site", authRequired: true },
    component: () => import("../views/general-setting"),
  },
];
